<script>
  import moment from 'moment'
  import convertUTCDateToLocalDate from '@/services/utils/covert-utc-to-local.js'

  export default {
    name: 'Timer',
    props: {
      expirationDate: {
        type: String,
        required: true
      },
      currentDate: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        remains: 0,
        interval: null
      }
    },
    computed: {
      formatedRemains() {
        const minutes = Math.floor(this.remains / 60)
        const seconds = this.remains - minutes * 60

        return `${minutes}:${seconds}`
      },
      errors() {
        if (this.remains <= 0) {
          return ['Time is over']
        }

        return []
      }
    },
    watch: {
      expirationDate: 'createTimer',
      currentDate: 'createTimer'
    },
    created() {
      this.createTimer()
    },
    methods: {
      createTimer() {
        if (this.interval) {
          clearInterval(this.interval)
        }

        const expirationTimestamp = +convertUTCDateToLocalDate(
          new Date(moment(this.expirationDate).valueOf())
        )
        const currentTimestamp = this.getCurrentTimestamp()

        this.remains = Math.round((expirationTimestamp - currentTimestamp) / 1000)

        this.interval = setInterval(() => {
          this.remains -= 1

          if (this.remains <= 0) {
            clearInterval(this.interval)
            this.$emit('end')
          }
        }, 1000)
      },
      getCurrentTimestamp() {
        let currentDate

        if (this.currentDate) {
          currentDate = convertUTCDateToLocalDate(new Date(moment(this.currentDate).valueOf()))
        } else {
          currentDate = new Date()
        }

        return currentDate.valueOf()
      }
    },
    render() {
      return this.$scopedSlots.default({
        remains: this.remains,
        formatedRemains: this.formatedRemains
      })
    }
  }
</script>
