<template>
  <v-dialog v-model="isShown" max-width="450px">
    <v-card :loading="isLoading">
      <v-card-title>
        {{ $t('login.forgot_password_header') }}
      </v-card-title>
      <v-card-text class="py-0">
        <v-flex v-if="!resultMessage">
          <p>{{ $t('login.enter_email') }}</p>
          <c-text-field
            :id="seleniumIds.SELENIUM_TEST_LOGIN_FORGOT_PASSWORD_FIELD"
            autocomplete="email"
            label="Email"
            required
            :error-messages="errors.email"
            :value="email"
            @input="setEmail($event)"
            @focus="errors.email = []"
          />
        </v-flex>

        <v-flex v-else>
          <p>{{ resultMessage }}</p>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <c-btn
          class="mr-2"
          text
          large
          :label="$t('main.close')"
          @click="closeDialog()"
        />

        <captcha
          v-slot="{ performVerifiedAction }"
          :error-messages="errors.recaptcha"
          @verified="send($event)"
        >
          <c-btn
            v-if="!resultMessage"
            class="white--text"
            color="primary"
            :disabled="isLoading"
            large
            block
            :label="$t('main.send')"
            @click="performVerifiedAction"
          />
        </captcha>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import personalRepository from '@/services/repositories/personal-repository.js'
  import ValidationError from '@/services/classes/validation-error.js'
  import Captcha from '@/components/Captcha.vue'

  export default {
    name: 'ResetPasswordDialog',
    components: {
      Captcha,
      CTextField,
      CBtn
    },
    inject: ['settings'],
    props: {
      value: Boolean
    },
    data() {
      return {
        email: '',
        errors: {},
        isLoading: false,
        resultMessage: '',
        seleniumIds
      }
    },
    computed: {
      isShown: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
          if (!val) {
            this.resultMessage = ''
            this.$emit('close')
          }
        }
      }
    },
    methods: {
      setEmail(email) {
        this.email = email
      },
      closeDialog() {
        this.isShown = false
        this.$emit('close')
      },
      async send(recaptcha) {
        this.isLoading = true
        this.errors = {}

        try {
          this.resultMessage = await personalRepository.sendForgetPasswordEmail({
            email: this.email,
            recaptcha
          })
        } catch (error) {
          if (error instanceof ValidationError) {
            this.errors = error.messages
          }
        }
        this.isLoading = false
      }
    }
  }
</script>
