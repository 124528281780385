<template>
  <v-expand-transition>
    <div v-if="codeFieldIsShown">
      <timer
        v-slot="{ remains, formatedRemains }"
        :current-date="codeSentAt"
        :expiration-date="codeExpirationTime"
        @end="setIsExpired(true)"
      >
        <div>
          <template v-if="!isExpired">
            {{ $t('login.sent_code') }} {{ $t(`login.${authenticationMethod}`) }}.
            <br />
            {{ $t('login.enter_code') }}
            <template v-if="authenticationMethodIsEmail">
              <br />
              {{ $t('login.in_case_you_dont_see_email') }}
              <br />
              {{ $t('login.check_spam') }}
            </template>
          </template>

          <c-text-field
            :label="$t('login.code')"
            outlined
            persistent-hint
            hide-details
            :disabled="isExpired"
            :error-messages="errors.code"
            :value="code"
            @input="setCode($event)"
          />

          <v-layout justify-space-between wrap>
            <div class="text-caption">
              {{ remains === 0 ? $t('login.expired') : formatedRemains }}
            </div>
            <br />
            <a v-if="!loading" class="text-caption" @click="resendCode()">
              {{ $t('login.resend') }}
            </a>
          </v-layout>
        </div>
      </timer>
    </div>
  </v-expand-transition>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import Timer from './Timer.vue'

  export default {
    name: 'ConfirmationCodeInput',
    components: {
      CTextField,
      Timer
    },
    props: {
      errorMessages: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapState('login', [
        'loading',
        'errors',
        'code',
        'authenticationMethod',
        'codeExpirationTime',
        'codeSentAt',
        'isExpired'
      ]),
      ...mapGetters('login', ['codeFieldIsShown']),
      authenticationMethodIsEmail() {
        return this.authenticationMethod === 'email'
      }
    },
    methods: {
      ...mapActions('login', ['setCode', 'setIsExpired']),
      resendCode() {
        this.setCode('')
        this.$emit('resend-code')
      }
    }
  }
</script>
