<template>
  <div>
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="settings.googleRecaptchaSitekey"
      size="invisible"
      :load-recaptcha-script="true"
      @verify="verifiedHandler($event)"
      @expired="resetCaptcha()"
    />

    <slot :perform-verified-action="performVerifiedAction" />

    <errors v-if="errorMessages.length || error" :error-messages="error || errorMessages" />
  </div>
</template>

<script>
  import { VueRecaptcha } from 'vue-recaptcha'
  import Errors from '@clickadilla/components/ui/Errors.vue'

  export default {
    name: 'Captcha',
    components: {
      Errors,
      VueRecaptcha
    },
    inject: ['settings'],
    props: {
      value: {
        type: String,
        default: ''
      },
      errorMessages: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        error: null
      }
    },
    methods: {
      performVerifiedAction() {
        this.error = null
        try {
          this.$refs.recaptcha.execute()
        } catch (error) {
          this.error = error.message
        }
      },
      resetCaptcha() {
        this.$refs.recaptcha.reset()
      },
      verifiedHandler(token) {
        this.$emit('verified', token)
        this.resetCaptcha()
      }
    }
  }
</script>
