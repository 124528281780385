import Cookies from 'js-cookie'
import settings from '@/settings.js'
import getDomainName from '@/services/utils/get-domain-name.js'

export default {
  created() {
    Cookies.remove(settings.rememberUserCookieKey, {
      domain: getDomainName(window.location.host)
    })
  }
}
